<template>
  <div :class="$style.home">
    <div :class="$style.top">
      <div :class="$style.head">
        <img v-if="data.state_bar" :src="oss + '/manage/media/assets/statusbar_b.png'" alt="" />
        <img v-else :src="oss + '/manage/media/assets/statusbar_w.png'" alt="" />
      </div>
      <img :class="$style.back" :src="data.cover.cover" />
      <div :class="$style.title">文化</div>
      <div :class="$style.input">
        <div :class="$style.cbox">
          <i class="ri-search-2-line" />
          搜索文化点
        </div>
      </div>
    </div>
    <div :class="$style.banner">
      <img v-if="data.swiper.length" :src="data.swiper[0].data.cover" />
      <div v-if="data.swiper.length > 1" :class="$style.docMode">
        <span v-for="(item, index) in data.swiper" :key="index" :class="$style.doc" />
      </div>
    </div>

    <div :class="$style.box">
      <div v-for="(item, index) in data.group" :key="index" :class="$style.item">
        <img :src="item.icon" />
        <p :class="$style.name">{{ item.name }}</p>
      </div>
    </div>

    <div :class="$style.more">
      更多目录
      <i class="ri-arrow-right-s-line" />
    </div>
    <p :class="$style.poctitle">推荐信息</p>
    <div :class="$style.poc">
      <div v-for="(item, index) in pocData" :key="index" :class="$style.item">
        <img :src="item.cover.cover" />
        <p :class="$style.name" class="ellipsis">{{ item.name }}</p>
        <p :class="$style.summary" class="ellipsis">{{ item.summary }}</p>
        <div :class="$style.tag">{{ item.group_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { editorService } from '@/service'

const service = new editorService()

export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    pocData() {
      return this.data.poc_type === 0 ? this.list : this.data.poc_list
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { data } = await service.hotPoc()
      this.list = data
    },
  },
}
</script>

<style lang="less" module>
.home {
  .top {
    position: relative;
    width: 100%;
    height: 400px;

    .head {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 44px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .back {
      position: absolute;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 400px;
      object-fit: cover;
    }

    .title {
      position: absolute;
      top: 44px;
      z-index: 1;
      width: 100%;
      height: 44px;
      color: #fff;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
    }

    .input {
      position: absolute;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 200px;
      background-image: linear-gradient(to bottom, transparent, #fff);

      .cbox {
        position: relative;
        width: 350px;
        height: 44px;
        margin: 136px auto 0;
        padding: 12px 50px;
        color: #5c5c5c;
        background-color: #fff;
        border-radius: 100px;

        i {
          position: absolute;
          top: 7px;
          left: 18px;
          color: #5c5c5c;
          font-size: 20px;
          vertical-align: -5px;
        }
      }
    }
  }

  .banner {
    width: 350px;
    height: 120px;
    margin: 0 auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 350px;
    margin: 20px auto 0;

    .item {
      width: 110px;
      height: 110px;
      margin-bottom: 10px;
      text-align: center;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

      img {
        width: 40px;
        height: 40px;
        margin: 20px auto 0;
        object-fit: cover;
      }

      .name {
        margin-top: 10px;
        margin-bottom: 0;
        color: #5c5c5c;
        font-size: 14px;
      }
    }
  }

  .box:after {
    content: '';
    width: 110px;
  }

  .more {
    width: 350px;
    height: 44px;
    margin: 0 auto;
    color: #5c5c5c;
    line-height: 44px;
    text-align: center;
    background-color: #fff;
    border-radius: 6px;
  }

  .poctitle {
    margin: 20px 0 14px 20px;
    color: #000;
    font-size: 18px;
  }

  .poc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 350px;
    margin: 0 auto;

    .item {
      position: relative;
      width: 170px;
      height: 184px;
      overflow: hidden;
      border-radius: 6px;
      border-radius: 6px;
      background: #fff;
      margin-bottom: 10px;

      img {
        width: 170px;
        height: 120px;
        object-fit: cover;
      }

      .summary {
        margin: 0 10px 10px 10px;
        color: #5c5c5c;
        font-size: 12px;
      }

      .name {
        margin: 10px 10px 5px 10px;
        color: #000;
        font-size: 16px;
      }

      .tag {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        padding: 0 5px;
        color: #000;
        font-size: 12px;
        line-height: 20px;
        background: #ffd287;
        border-radius: 6px 0 6px 0;
      }
    }
  }
  .doc-mode {
    position: absolute;
    bottom: 0%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);

    .doc {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin-right: 5px;
      line-height: 20px;
      background-color: #fff;
      border-radius: 5px;
    }

    .doc:first-child {
      width: 12px;
      background: @primary-color;
    }
  }
}
</style>
