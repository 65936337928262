<template>
  <div :class="$style.home">
    <div :class="$style.left">
      <left :data="data" />
    </div>
    <div id="right" :class="$style.right">
      <a-tabs v-model="active">
        <a-tab-pane key="1" tab="基础设置">
          <div :class="$style.box">
            <div :class="$style.boxLeft">
              <span class="required">*</span>
              状态栏
            </div>
            <div :class="$style.boxRight">
              <a-radio-group v-model="data.state_bar">
                <a-radio :value="0"> 白色 </a-radio>
                <a-radio :value="1"> 黑色 </a-radio>
              </a-radio-group>
              <div class="tip">设置状态栏样式，状态栏样式仅对小程序生效。</div>
            </div>
          </div>

          <div :class="$style.box">
            <div :class="$style.boxLeft">
              <span class="required">*</span>
              主题图片
            </div>
            <div :class="$style.boxRight">
              <selectMaterial :data="data.cover" :allow="['image']" @change="coverChange" />
              <div class="tip mt-10">主题图片建议尺寸780px*800px；大小不超过2M；格式支持JPG、PNG。</div>
            </div>
          </div>

          <div :class="[$style.box, $style.mb10]">
            <div :class="$style.boxLeft">
              <span class="required">*</span>
              推荐分组
            </div>
            <div :class="$style.boxRight">
              <BjButton class="btn-default" @click="onAddGroup()">
                <i class="ri-edit-2-line left" /> 选择分组
              </BjButton>
              <div :class="$style.groupBox">
                <Draggable v-model="data.group">
                  <transition-group :class="$style.group">
                    <div v-for="item in data.group" :key="item.id" :class="$style.groups">
                      <img :src="item.icon" />
                      <div :class="$style.groupName" class="overflow">
                        {{ item.name }}
                      </div>
                      <div :class="$style.block">
                        <i class="ri-menu-line" />
                        按住拖动可排序
                      </div>
                    </div>
                  </transition-group>
                </Draggable>
              </div>
            </div>
          </div>

          <div :class="$style.box">
            <div :class="$style.boxLeft">
              <span class="required">*</span>
              推荐信息
            </div>
            <div :class="$style.boxRight">
              <a-radio-group v-model="data.poc_type">
                <a-radio :value="0"> 热门文化点 </a-radio>
                <a-radio :value="1"> 自定义数据 </a-radio>
              </a-radio-group>
              <div class="tip">热门文化点将显示访问量最高的文化点。</div>
            </div>
          </div>
          <div v-if="data.poc_type === 1" :class="$style.box">
            <div :class="$style.boxLeft">
              <span class="required">*</span>
              选择文化点
            </div>
            <div :class="$style.boxRight">
              <BjButton class="btn-default mb-10" @click="onAddPoint()">
                <i class="ri-edit-2-line left" /> 选择文化点
              </BjButton>
              <div :class="$style.point">
                <div
                  v-for="(item, pIndex) in data.poc_list.slice(0, show ? data.poc_list.length : 10)"
                  :key="item.id"
                  :class="$style.pointBox"
                >
                  <img :src="item.cover.cover" />
                  <div :class="$style.text">
                    <p class="overflow" :title="item.name">{{ item.name }}</p>
                    <p class="overflow" :title="item.group_name">{{ item.group_name }}</p>
                  </div>
                  <div :class="$style.btn">
                    <i class="ri-eye-line" @click="onEditPoint(item)" />
                    <i class="ri-delete-bin-7-line" @click="onDeletePoint(item, pIndex)" />
                  </div>
                </div>
              </div>
              <div v-if="data.poc_list.length > 10" :class="$style.more">
                <span :class="$style.moreLeft">文化点数量：{{ data.poc_list.length }}条</span>
                <span :class="$style.moreRight" @click="onShow()">
                  {{ !show ? '展开全部' : '显示部分' }}
                  <i v-if="!show" class="ri-arrow-down-s-line" />
                  <i v-else class="ri-arrow-up-s-line" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="active === '1'" :class="$style.box">
            <div :class="$style.boxLeft">&nbsp;</div>
            <div :class="$style.boxRight">
              <BjButton type="primary" @click="onSave()">
                <i class="ri-save-line left" />
                立即保存
              </BjButton>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="轮播图片">
          <div :class="$style.search">
            <BjButton
              type="primary"
              :disabled="data.swiper.length > 9"
              :tooltips="data.swiper.length > 9 ? '轮播图片最多设置10条，超过10条将不能再次添加。' : ''"
              @click="onAddSwiper()"
            >
              <i class="ri-add-line left" /> 添加轮播
            </BjButton>
          </div>
          <a-table :columns="columns" :data-source="data.swiper" :class="$style.table" :pagination="false" row-key="id">
            <template #cover="item">
              <img :class="$style.tableCover" :src="item.data.cover" />
            </template>
            <!-- prettier-ignore -->
            <template #action="item, e, tIndex">
              <bj-link type="primary" @click="onEditSwiper(item, tIndex)"> 编辑 </bj-link>
              <a-popconfirm title="确定要删除吗？" placement="topLeft" @confirm="onDeleteSwiper(tIndex)">
                <bj-link type="danger"> 删除 </bj-link>
              </a-popconfirm>
              </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
      <!-- <div v-if="active === '1'" :class="$style.footer" :style="{ width: footWidth }">
        <BjButton type="primary" @click="onSave()">
          <i class="ri-save-line left" />
          立即保存
        </BjButton>
      </div> -->
    </div>
    <bj-modal
      :title="title"
      :visible="visible.swiper"
      :width="550"
      :body-style="{ height: '520px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <BjForm ref="edit">
          <BjInput
            v-model="edit.title"
            label-align="left"
            v-bind="layout"
            rules="required"
            tooltips
            placeholder="请输入轮播标题"
            label="轮播标题"
          />
        </BjForm>
        <BjValidationItem rules="required" label-align="left" label="轮播图片" v-bind="layout">
          <selectMaterial :data="edit.data" :allow="['image']" @change="swiperChange" />
          <p class="tip mt-10">轮播图片建议尺寸700px*240px；大小不超过2M；格式支持JPG、PNG。</p>
        </BjValidationItem>
        <BjValidationItem rules="required" label-align="left" label="跳转链接" v-bind="layout">
          <BjButton class="btn-default" @click="onAddLink()">
            <i class="ri-link left" />
            设置链接
          </BjButton>
          <p v-if="edit.link" class="primary">{{ edit.link.label }}</p>
          <p class="tip mt-10">请选择点击轮播图片需要跳转的链接。</p>
        </BjValidationItem>
      </div>
    </bj-modal>
    <selectGroup :visible.sync="visible.group" :select="data.group" :multiple="true" @change="getGroup" />
    <chooseLink :visible.sync="visible.link" :data="edit.link" @change="getLink" />
    <choosePoint :visible.sync="visible.point" :select="data.poc_list" :multiple="true" @change="pointChange" />
  </div>
</template>

<script>
import _ from 'lodash'
import Draggable from 'vuedraggable'

import chooseLink from '@/components/chooseLink'
import choosePoint from '@/components/choosePoint'
import selectMaterial from '@/components/selectMaterial'
import { terminalScreenService } from '@/service'

import left from './left.vue'
import selectGroup from './selectGroup.vue'

const service = new terminalScreenService()

export default {
  name: 'Home',
  components: {
    selectMaterial,
    chooseLink,
    selectGroup,
    choosePoint,
    Draggable,
    left,
  },
  data() {
    return {
      active: '1',
      visible: {
        link: false,
        group: false,
        point: false,
        swiper: false,
      },
      data: {
        state_bar: 0,
        poc_type: 0,
        cover: {},
        poc_list: [],
        swiper: [],
      },
      //   add: {
      //     link: {},
      //     title: null,
      //     image: {},
      //     data: {},
      //   },
      show: false,
      footWidth: '800px',
      title: '添加轮播',
      edit: {
        // 弹窗变量
        data: {},
        link: null,
        title: null,
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      index: null, // 编辑轮播的index
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '轮播标题',
          dataIndex: 'title',
        },
        {
          title: '轮播图片',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '跳转链接',
          customRender: item => item.link.label || '-',
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getInfo()
  },
  mounted() {
    this.footWidth = document.getElementById('right').offsetWidth + 'px'
    window.onresize = () => {
      this.footWidth = document.getElementById('right').offsetWidth + 'px'
    }
  },
  methods: {
    coverChange(data) {
      this.data.cover = data[0]
    },
    swiperChange(data) {
      this.edit.data = data[0]
    },
    async getInfo() {
      try {
        const { data } = await service.getInfo({
          page_type: 'culture',
        })
        this.data = data
      } catch (e) {}
    },
    getLink(data) {
      this.edit.link = data
    },
    onAddSwiper() {
      this.visible.swiper = true
      this.index = null
      this.edit = {
        data: {},
        link: null,
        title: null,
      }
    },
    getGroup(data) {
      this.data.group = data
    },
    pointChange(data) {
      this.data.poc_list = data
    },
    onAddGroup() {
      this.visible.group = true
    },
    onAddPoint() {
      this.visible.point = true
    },
    async onSave() {
      try {
        await service.save({
          page_type: 'culture',
          data: JSON.stringify(this.data),
        })
        this.$message.success('保存成功')
        this.getInfo()
      } catch (e) {}
    },
    onShow() {
      this.show = !this.show
    },
    onEditPoint(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'poc',
          id: item.id,
        },
      })
    },
    onDeletePoint(item, index) {
      this.data.poc_list.splice(index, 1)
    },
    async handleOk() {
      try {
        if (this.index !== null) {
          this.data.swiper.splice(this.index, 1, this.edit)
        } else {
          this.data.swiper.push(this.edit)
        }
        await service.save({
          page_type: 'culture_swiper',
          data: JSON.stringify(this.data),
        })
        this.getInfo()
        this.$message.success('保存成功')
        this.handleCancel()
      } catch (e) {
        this.getInfo()
      }
    },
    handleCancel() {
      this.visible.swiper = false
    },
    onAddLink() {
      this.visible.link = true
    },
    onEditSwiper(item, index) {
      this.edit = _.cloneDeep(item)
      this.index = index
      this.visible.swiper = true
    },
    async onDeleteSwiper(index) {
      try {
        this.data.swiper.splice(index, 1)
        await service.save({
          page_type: 'culture_swiper',
          data: JSON.stringify(this.data),
        })
        this.$message.success('保存成功')
        this.getInfo()
      } catch (e) {
        this.getInfo()
      }
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;

  .left {
    width: 390px;
    min-width: 390px;
    margin-right: 20px;
    border: 1px solid #eee;
  }

  .right {
    position: relative;
    flex: 1;
    min-width: 750px;
    height: calc(100vh - 152px);
    padding: 20px;
    padding-bottom: 60px;
    overflow-y: auto;
    background: #fff;
  }

  .mb10 {
    margin-bottom: 10px !important;
  }

  .box {
    display: flex;
    margin-bottom: 20px;
    // flex-wrap: wrap;
    &-left {
      width: 140px;
      height: 40px;
      line-height: 40px;
    }

    &-right {
      width: 600px;
      line-height: 40px;
    }
  }

  .footer {
    position: fixed;
    right: 20px;
    bottom: 20px;
    height: 60px;
    padding-left: 20px;
    line-height: 60px;
    background: #fff;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);
  }

  .more {
    width: 590px;
    font-size: 12px;
    line-height: 25px;

    &-left {
      color: #5c5c5c;
    }

    &-right {
      float: right;
      color: @primary-color;
      cursor: pointer;
      /* stylelint-disable-next-line no-descending-specificity */
      i {
        font-size: 17px;
        vertical-align: -3px;
      }
    }
  }

  .group-box {
    margin-top: 6px;

    .group {
      display: flex;
      flex-wrap: wrap;
    }

    .block {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 140px;
      height: 50px;
      color: #fff;
      line-height: 50px;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.6);
      cursor: grab;

      i {
        color: #fff;
        font-size: 17px;
        vertical-align: -3px;
      }
    }

    .groups:hover .block {
      display: block;
    }

    .groups {
      position: relative;
      display: flex;
      align-items: center;
      width: 140px;
      height: 50px;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #eee;

      img {
        width: 30px;
        height: 30px;
        margin: 0 10px;
        object-fit: cover;
      }

      .group-name {
        flex: 1;
        color: #5c5c5c;
        font-size: 14px;
      }
    }
  }

  .point {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 590px;

    .point-box {
      display: flex;
      align-items: center;
      width: 290px;
      height: 70px;
      margin-bottom: 10px;
      padding: 5px;
      border: 1px solid #eee;

      img {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }

      .text {
        flex: 1;
        max-width: 144px;
        margin-bottom: 6px;
        padding-top: 10px;
        line-height: 14px;

        p:nth-child(2) {
          color: #5c5c5c;
          font-size: 12px;
        }
      }

      .btn {
        width: 64px;
        font-size: 18px;

        i {
          margin-left: 10px;
        }

        i:nth-child(1) {
          color: @primary-color;
        }
      }
    }
  }

  .search {
    margin-bottom: 20px;
    text-align: right;
  }

  .table {
    &-cover {
      width: 88px;
      height: 30px;
      object-fit: cover;
    }
  }
}
</style>
